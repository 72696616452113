.only-sp {
  @include pc {
    display: none;
  }
}

.only-pc {
  @include mediaM {
    display: none;
  }
}

.section {
  padding-left: 80px;
  padding-right: 80px;


  @include mediaS {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-x {
  padding-bottom: 100px;
  padding-top: 84px;

  @include mediaS {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.common-title {
  text-align: center;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    position: relative;
    display: inline-block;
    border-bottom: 2px solid $blue;
    margin-bottom: 66px;

    @include mediaS {
      font-size: 1.25rem;
      margin-bottom: 30px;
    }
  }
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

h5,
h4,
h3,
h2 {
  margin-block-start: 0;
  margin-block-end: 0;
}

h1 {
  margin-block-start: 0;
  margin-block-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $default;
}

body {
  height: auto !important;
  margin: 0;
  font-family: $default;
}

.job-title {
  font-size: 1.25rem;

  @include mediaS {
    font-size: 1rem;
  }
}

.card {
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  border-radius: 8px;
}

.underline {
  text-decoration: underline 3px $orange;
}

.emphasis-simple {
  font-size: 50px;
  color: $blue;

  @include mediaS {
    font-size: 30px;
  }
}

.nonpc {
  @include pc {
    display: none;
  }

  @include mediaM {
    display: none;
  }

  @include mediaS {
    display: block;
  }
}

.max-extend {
  max-width: 1920px;
  margin: auto;

}


.sub-title {
  font-size: 50px;
  margin-bottom: 40px;

  @include sp {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.title-common {
  .title-img {
    width: 660px;

    @include sp {
      width: 58%;
    }
  }

  span {
    font-size: 24px;
    color: black;
    position: relative;
    margin-left: 30px;

    @include sp {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  span::before {
    content: "";
    position: absolute;
    bottom: 0;
    // right: -40px;
    right: 0;
    width: 120%;

    height: 1px;
    background-color: black;
  }
}

.dark-mode span::before {
  background-color: $dark-text;
}

.yellow-text{
  color:$yellow;
}
.box-shadow{
  color: #000;
  text-shadow: 1px 4px 2px rgba(171, 186, 0, 0.25);
font-size: 18px;
font-family: $default;
font-weight: 900;
letter-spacing: 1.8px;
}

.title-right{
  justify-content: end;
  align-items: end;
}
.title-left{
  justify-content: end;
  align-items: start;

}
.common-title{
  display: flex;

  flex-direction: column;

font-family: $default;

letter-spacing: 3px;
h2{
  font-size: 30px;
  font-weight: 900;

}
h3{
  font-size: 16px;
  font-family: $default;
  font-weight: 900;
}

}


.common-button{
  display: flex;
  justify-content: end;

}
.more-link{
  color: #000;
  font-size: 16px;
  font-family: $fonttitle;
  font-weight: 700;
  padding-left: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right:60px ;
  background-color: white;
  display: inline-block;
  position: relative;



}
.more-link::before { /* くの字の表示設定 */
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 22px;
  width: 7px;    /* くの字を山なりに見た時、左側の長さ */
  height: 7px;   /* くの字を山なりに見た時、右側の長さ */
  border-top: 3px solid $yellow;     /* くの字を山なりに見た時、左側の太さと色 */
  border-right: 3px solid $yellow;   /* くの字を山なりに見た時、右側の太さと色 */
  transform: rotate(45deg);    /* くの字の向き */
}