@charset "UTF-8";

/*# sourceMappingURL=style.css.map */

/*--共通CSS--*/
@import "mixins/prepends";
@import "mixins/variables";
@import "mixins/common";

/*--プロジェクトごとのCSSは以下にimport--*/
@import "global-header";
@import "footer";
// @import "footer";

/*---------------------*/



@import "module/switch.scss";
@import "module/toppage.scss";
@import "module/intro.scss";
@import "module/about.scss";
@import "module/service.scss";
@import "module/recruit.scss";
@import "module/contact.scss";


.animate-up {
    transform: translateY(100px);
    opacity: 0;
    visibility: hidden;
    animation: animate_up 2s forwards;
}

@keyframes animate_up {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

.animate-left {
    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden;
    animation: animate_left 2s forwards;
}

@keyframes animate_left {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }
}

.animate-right {
    transform: translateX(100px);
    opacity: 0;
    visibility: hidden;
    animation: animate_right 2s forwards;
}

@keyframes animate_right {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }
}

.animate__animated.animate__fadeInLeft {
    --animate__delay: 5s;
    --animate-duration: 3s;
}

.animate__animated.animate__fadeInRight {
    --animate__delay: 5s;
    --animate-duration: 3s;
}

.animate__animated.animate__bounceIn {
    --animate__delay: 100s;
    --animate-duration: 8s;
}