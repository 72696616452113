/*--色コード(プロジェクトによって変更してください。)--*/
$black: #2b2b2b;
$gray: #f5f5f5;
$gray-light: #e7e7e7;
$gray-highlight: #f6f6f6;
$orange-highlight: #fff6e8;
$orange-light: #fde9c8;
$orange: #f39700;
$brown: #b15b26;
$brown-dark: #662c18;
$backwhite: #fffcf2;
$white: #fff;
$red: #e72c2e;
$green: #009c77;
$pale-black: #5f5958;
$beige: #F9F3EB;
$blue: #006CC7;
$blue-light:#8EC3F0;
$orange:#FFBA00;
$grey:#EDEDED;

$dark-body:#051931;
$dark-text:#00FFFF;


/////色
$yellow:#EAD921;
$light-yellow:#FFFBD8;
/*---------------------------*/


$default: 'M PLUS 1p', sans-serif;


$NotoSerif: "Noto Serif JP", serif;

@mixin mediaM($width-media: 992px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaS($width-media: 768px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}

@mixin mediaXS($width-media: 576px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}

@mixin tb($width-media: 768px) {
  @media screen and (min-width: $width-media) {
    @content;
  }
}

//----------------------------------------------
@mixin pc {
  @media (min-width: 960px) {
    @content;
  }
}
@mixin sp {
  @media (max-width: 960px) {
    @content;
  }
}

// @mixin tb($width-media: 1100px) {
//   @media screen and (max-width: $width-media) {
//     @content;
//   }
// }
// @mixin sp {
//   @media (max-width: 960px) {
//     @content;
//   }
// }

////////////////////
// フォント設定
////////////////////

$fonttitle: 'M PLUS 2', sans-serif;
$font2: "Noto Serif JP", serif;
$font3: "Noto Sans", sans-serif;



h1{
  font-family: $fonttitle;
}
