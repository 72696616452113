.template-schedule{
    .schedule-ttl{
        margin-top: 60px;
        text-align: center;
        margin-bottom: 42px;
    }h3{
        color: #000;
font-size: 28px;
font-family: $default;
font-weight: 900;
letter-spacing: 3px;
    }
        .schedule-list-wrapper{
            .schedule-list{


                .schedule{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 30px;
                    @include mediaM{
                      display: block;
                    }

                    .time{
                        width: 50%;
                        display: flex;
                      align-items: center;
                        gap: 50px;
                        @include mediaM{
                          width: 100%;
                          margin-bottom: 20px;
                        }
                        .clock{
                            width: 84px;
                            img{
                                width: 100%;
                            }

                        }
                        .schedule-exp{
                            .ttl{
                                color: #000;
font-size: 20px;
font-family: $default;
font-weight: 800;
letter-spacing: 2px;
                            }
                            .txt{
                                color: #000;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;

                            }
                        }
                    }
                }
                .schedule-img{
                    width: 50%;
                    @include mediaM{
                      display: flex;
                      justify-content: end;
                      width: 100%;
                    }
                    display: flex;
                    justify-content: end;
                    .schedule-img-wrapper{
                        width: 270px;
                        img{
                            width: 100%;
                        }

                    }

                }
            }
        }
    }


    .interview-section{
        .interview-box{
          
            .interview-list{
                margin-top: 42px;

                display: flex;
                gap: 120px;
                @include mediaM{
                    display: block;
                }
                .interview{
                    width: 50%;
                    margin-bottom: 30px;
                    @include mediaM{
                        width: 100%;
                    }
                    .interviewer-img{
                        .interviewer-img-wrapper{
                            width: 420px;
                            @include mediaM{
                                width: 300px;
                            }
                            @include mediaS{
                                width: 100%;
                            }
                            img{
                                width: 100%;
                            }

                        }

                    }
                    .interview-answer{
                        padding-top: 230px;
                        background-color: black;
                        padding-left: 30px;
                        padding-right: 30px;
                        margin-top:-200px;
                        padding-bottom: 42px;
                        position: relative;
                        z-index: -2;
                        @include mediaM{
                            padding-left: 14px;
                            padding-right: 14px;

                        }
                    

                        .interviewer-name{
                            position: relative;
                            display: inline-block;
                            margin-bottom: 20px;
                            p{
                                color: #FFF;
font-size: 18px;
font-family: $default;
font-weight: 800;
letter-spacing: 1.8px;

                            }

                          
                        }

                        .interviewer-name::before{
                            content:"";
                            width: 100%;
                            height: 6px;
                            background-color: $yellow;
                            position: absolute;
                            bottom: 2px;
                       left: 0px;
                       z-index: -1;
                        }
                        .interviewer-text{
                            color: #FFF;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;
                           }
                    }


                    
                }
            }
        }
    }




        
          .template-recruit {
        
            padding-top: 80px;
            padding-bottom: 140px;
            @include mediaS{
             padding-top: 40px;
             padding-bottom: 40px;
            }
          
        .recruit-content-wrapper{
          display: flex;
          justify-content: center;
        }
            .recruit__items {
              width: 80%;
        
              @include mediaM {
                width: 100%;
                // max-width: rem(673);
                // margin-left: rem(60);
              }
            }
            
            .recruit__item {
              position: relative;
              display: flex;
              justify-content: space-between;
            
              align-items: center;
              border-bottom: 1px solid grey;
             
        
            
              //   @include mediaM {
              //     padding-top: rem(12);
              //     padding-bottom: rem(12);
              //   }
            }
            

            
           
        
          }
          .recruit__description {
            text-align: left;
            width: calc(100% - 35%);
           font-size: 1rem;
            color: $black;
            margin-left: rem(23);
            @include mediaM {
              font-size: 0.875rem;
              width: calc(100% - 35%);
            }
          }
          .underline-2 {
            width: 100%;
            height: 1px;
            background-color: $pale-black;
          }
          #template-recruit-content {
            text-align: center;
            display: block;
        
          }
          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 43px;
            font-size: 1rem;
            @include mediaS{
              margin-top: 20px;
              font-size: 0.875rem;
            }
        
        
            .detail {
              // background-color: $green;
              display: block;
        
              z-index: 2;
         
              color: #fff;
              padding: 16px 70px;
              background: $red;
              border-radius: 8px;
              @include mediaS{
                padding: 14px 40px;
              }
              
            }
           
  
        
        
          }
          .recruit-button-wrapper{
              display: flex;
              justify-content: center;
              .bgyellow{
                  background-color: $yellow;
                  position: relative;
              }
              .bgyellow::before { /* くの字の表示設定 */
                  content: "";
                  margin: auto;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 20px;
                  width: 7px;    /* くの字を山なりに見た時、左側の長さ */
                  height: 7px;   /* くの字を山なりに見た時、右側の長さ */
                  border-top: 3px solid white;     /* くの字を山なりに見た時、左側の太さと色 */
                  border-right: 3px solid white;   /* くの字を山なりに見た時、右側の太さと色 */
                  transform: rotate(45deg); 
                     /* くの字の向き */
                     @include mediaM{
                      right: 40px;
                     }
                }
              
                  }
                  .recruit__term {
                    padding-bottom: 18px;
                    padding-top: 18px;
                    font-size: 1rem;
                  
                  
                    font-weight: bold;
                    color: $black;
                    //   max-width: rem(90);
                    width: 30%;
                    background-color:$light-yellow !important;
                    height: auto;
                    text-align: center;
                  
                    @include mediaM {
                      width: 30%;
                      font-size: 0.875rem;
                     
                    }
                  }