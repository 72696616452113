@charset "UTF-8";
/*--共通CSS--*/
/*--色コード(プロジェクトによって変更してください。)--*/
/*---------------------------*/
/*共通部分*/
html {
  font-size: 100%;
  letter-spacing: 0.22em;
  visibility: hidden;
}

html.wf-active {
  visibility: visible;
}

body {
  margin-left: auto;
  margin-right: auto;
  font-family: "Noto Sans JP", apple-system, blinkMacSystemFont, "Helvetica Neue", "Segoe UI", YuGothicM, YuGothic, Meiryo, "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3", sans-serif;
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: 1.2px;
}

body.fixed {
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.max-extend {
  max-width: 1920px;
  margin: auto;
}

.SiteWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h3 {
  font-family: serif;
  font-weight: bold;
}

a {
  text-decoration: none;
}

.common-content {
  margin: auto;
  max-width: 1206px;
}

.title-common {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title-common h1 {
  font-size: 35px;
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 700;
}
@media screen and (min-width: 992px) {
  .title-common h1 {
    font-size: 48px;
    margin-bottom: 15px;
  }
}
.title-common .jp-title {
  display: block;
  font-size: 10px;
  position: relative;
}
@media screen and (min-width: 992px) {
  .title-common .jp-title {
    font-size: 14px;
  }
}
.title-common .jp-title::before {
  position: absolute;
  content: "";
  display: block;
  bottom: -8px;
  width: 100%;
  height: 2px;
  margin: auto;
  background-color: #009c77;
}
@media screen and (min-width: 992px) {
  .title-common .jp-title::before {
    width: 100%;
    height: 4px;
    bottom: -12px;
  }
}

.common-content {
  text-align: center;
}

@media screen and (min-width: 992px) {
  .only-sp {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .only-pc {
    display: none;
  }
}

.section {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 80px;
}
@media screen and (min-width: 992px) {
  .section {
    margin-left: 120px;
    margin-right: 120px;
  }
}
@media screen and (min-width: 992px) {
  .section {
    margin-bottom: 120px;
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.text {
  margin: auto;
  margin-top: 25px;
  font-size: 0.875rem;
}
@media screen and (min-width: 992px) {
  .text {
    font-size: 1rem;
    margin-top: 73px;
  }
}

@media screen and (min-width: 992px) {
  .qa-box {
    margin-bottom: 140px;
  }
}

h2 {
  font-family: serif;
}

/*--プロジェクトごとのCSSは以下にimport--*/
header {
  z-index: 1000;
  top: 0;
  left: 0;
}

.page-header {
  width: 100%;
  background-color: #fff;
}

.comnpany-info {
  width: 60%;
}
@media screen and (max-width: 992px) {
  .comnpany-info {
    display: none !important;
  }
}

.logo-header {
  display: flex;
  justify-content: space-between;
  padding-right: 60px;
  padding-left: 60px;
  padding-top: 22px;
  padding-bottom: 22px;
}
@media screen and (max-width: 768px) {
  .logo-header {
    display: none;
  }
}
.logo-header .logo-info {
  width: 40%;
}
.logo-header .info-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-header .info-bottom .address {
  font-size: 14px;
  margin-bottom: 15px;
}
.logo-header .info-bottom .phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-header .info-bottom .phone span {
  font-size: 40px;
  color: #009c77;
  margin-right: 10px;
}
.logo-header .info-bottom .phone .phonenumber {
  font-size: 28px;
  font-weight: bold;
}
.logo-header .info-bottom .business-hour {
  font-size: 16px;
}
.logo-header .logo {
  color: #fff;
  font-size: 1.875rem;
  line-height: 0;
  width: 140px;
}

.nav-bar {
  width: 100%;
  display: block;
  background-color: #009c77;
  justify-content: space-between;
  padding: 13px 0;
}
@media screen and (max-width: 768px) {
  .nav-bar {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
    background-color: white;
  }
}
.nav-bar .logo {
  color: #fff;
  font-size: 1.875rem;
  line-height: 0;
  width: 140px;
  display: none;
}
@media screen and (max-width: 768px) {
  .nav-bar .logo {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .nav-bar .global-menu {
    padding-right: 20px;
    transition: all 0.3s ease-out;
    margin-top: inherit;
    position: fixed;
    height: 100vh;
    top: 0;
    left: -450px;
    background-color: #fff;
    width: 350px;
  }
  .nav-bar .global-menu .menu-list {
    display: none !important;
  }
  .nav-bar .global-menu.show {
    left: 0;
  }
  .nav-bar .global-menu.show .menu-list {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex !important;
    opacity: 0;
    -webkit-animation: menuFadein 0.5s forwards;
            animation: menuFadein 0.5s forwards;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 15%;
  }
  .nav-bar .global-menu.show .menu-list li {
    margin-bottom: 10px;
  }
}
.nav-bar .global-menu .menu-list {
  padding-right: 60px;
  padding-left: 60px;
  list-style: none;
  display: flex;
  width: 100%;
}
.nav-bar .global-menu .menu-list li {
  width: 100%;
  white-space: nowrap;
  position: relative;
}
.nav-bar .global-menu .menu-list li a {
  color: white;
  font-size: 1rem;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  .nav-bar .global-menu .menu-list li a {
    color: #009c77;
    border-bottom: 1px solid #000;
    margin-bottom: 15px;
  }
}
.nav-bar .global-menu .menu-list li:not(:last-child) {
  border-right: 1px solid white;
}

@-webkit-keyframes menuFadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes menuFadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.nav-cart {
  display: flex;
  align-items: center;
}
.nav-cart:hover:before {
  display: none;
}
.nav-cart .cart-icon {
  font-size: 1.5rem;
}

.toggle-hamburger {
  display: none;
}
@media screen and (max-width: 768px) {
  .toggle-hamburger {
    margin-right: 20px;
    display: block;
    cursor: pointer;
    width: 30px;
    height: 20px;
    position: absolute;
    right: 0;
    z-index: 105;
  }
  .toggle-hamburger .hamburger_line-1 {
    display: block;
    position: relative;
  }
  .toggle-hamburger .hamburger_line-1:before {
    position: absolute;
    content: "";
    width: 30%;
    height: 2px;
    background-color: black;
    top: 14px;
    right: 0;
    transform: translateY(0) rotate(0);
    transition: all 0.3s;
  }
  .toggle-hamburger .hamburger_line-2 {
    position: relative;
    display: block;
  }
  .toggle-hamburger .hamburger_line-2::before {
    position: absolute;
    content: "";
    width: 70%;
    height: 2px;
    background-color: black;
    top: 16px;
    right: 0;
    transform: translateY(0) rotate(0);
    transition: all 0.3s;
  }
  .toggle-hamburger .hamburger_line-2:nth-of-type(2)::before {
    transform: translateY(10px);
  }
  .toggle-hamburger .hamburger_line-3 {
    display: block;
    position: relative;
  }
  .toggle-hamburger .hamburger_line-3:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: black;
    top: 38px;
    right: 0;
    transform: translateY(0) rotate(0);
    transition: all 0.3s;
  }
  .toggle-hamburger p {
    font-size: 0.75rem;
    letter-spacing: 0;
    text-align: center;
    color: #5f5958;
    transform: translateY(20px);
  }
  .toggle-hamburger.close span:before {
    background-color: black;
  }
  .toggle-hamburger.close span:nth-of-type(1)::before {
    width: 100%;
  }
  .toggle-hamburger.close span:nth-of-type(2)::before {
    width: 100%;
  }
  .toggle-hamburger.close span:nth-of-type(2)::before {
    width: 100%;
  }
}

.headerNav__list {
  position: relative;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .page-header.change-color {
    background-color: #f5f5f5;
  }
}

.footer {
  height: 200px;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
  background-image: url(../../img/top_bottom_.jpg);
  background-attachment: fixed;
}
@media screen and (min-width: 992px) {
  .footer {
    height: 300px;
    background-image: url(../../img/top_bottom_.jpg);
  }
}

.footer-content {
  width: 100%;
  height: auto;
  background-color: skyblue;
  -webkit-clip-path: square(50px at center);
          clip-path: square(50px at center);
}

.footer-light {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #676160;
  text-align: center;
  padding: 15px 0;
}

/*---------------------*/
/*# sourceMappingURL=style.css.map */