#intro{
  .intro-wrapper{
    .intro-box{
      margin-top: 40px;
      .intro-flex{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 60px;
        @include mediaM{
display: block;
        }
        .intro-photo{
          width: 440px;
          @include mediaM{
            width: 100%;
            margin-bottom: 40px;
          }
          img{
            width: 100%;
          }

        }
        .intro-text{
          width: calc(100% - 500px);
          @include mediaM{
            width: 100%;
          }
    
          
          .intro-text-wrapper{
    max-width: 500px;

          }
          .intro-ttl{
            .ttl1{
              color: #000;
font-size: 30px;
font-family:$fonttitle;
font-weight: 700;
@include mediaM{
  font-size: 20px;

}

            }
            .ttl2{
              color: #000;
font-size: 40px;
font-family: $fonttitle;
font-weight: 700;
@include mediaM{
  font-size: 30px;
}

            }

          }
          .intro-exp{
            background-color:  #000;
            padding: 16px;
            p{color: white;
              font-size: 16px;
              font-family:$default;
              font-weight: 800;
              letter-spacing: 1.6px;
              @include mediaM{
                font-size: 16px;
              }
            
            }
          }
        }
      }
    }

  }
}