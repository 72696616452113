@import url('https://fonts.cdnfonts.com/css/courier-new');

header {
  z-index: 1000;
  top: 0;
  left: 0;
}
.page-header {
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.nav-bar {
  // max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
// width: 100%;
  // opacity: 0.8;
  // padding-top: 12px;
  // padding-bottom: 12px;

  @include mediaM {
    // padding-top: 14px;
    // padding-bottom: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  // .nav-bar {
  // width: 100%;
  // display: flex;
  //   background-color:$beige;
  //   justify-content: space-between;

  //   padding: 13px 0;

  //   }

  .logo-info {
    display: flex;
    align-items: center;
    width: 200px;
  }
  .logo {
    // padding-left: 14px;
    color: $white;
    font-size: 1.875rem;
    line-height: 0;

    width: 100%;

    @include mediaS {
      display: block;
    }
  }
  .global-menu {
    // background-color: white;
    width: calc(100% - 240px);
    margin-left: 100px;
    display: flex;
    align-items: center;
    justify-content: end;

    @include mediaM {
      margin-left: 0;
      width: 100%;
      justify-content: end;
    }

    @include mediaS {
      padding-right: 20px;
      transition: all 0.3s ease-out;
      margin-top: inherit;
      position: fixed;
      height: 100vh;
      top: 0;
      left: -450px;
      // background-color: $white;

      width: 350px;
      .menu-list {
        display: none !important;
      }

      //グローバルナビの表示非表示
      &.show {
        left: 0;

        .menu-list {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          display: flex !important;
          opacity: 0;
          animation: menuFadein 0.5s forwards;
          animation-delay: 0.5s;
          flex-direction: column;
          align-items: flex-start;
          padding-top: 15%;

          li {
            margin-bottom: 10px;
            // text-align: center;
          }
        }
      }
    }

    .menu-list {
      list-style: none;
      display: flex;
      justify-content: end;
      align-items: center;
      width: calc(100% - 220px);
      @include mediaM {
        width: calc(100% - 60px);
      }

      li {
        white-space: nowrap;
        position: relative;
        width: 100%;

        a {
          font-family: $fonttitle;
          // color: black;

          font-size: 18px;
          font-weight: bold;

          @include mediaS {
            font-size: 16px;
            // color: $blue;
            border-bottom: 1px solid #000;
            margin-bottom: 15px;
          }
        }
      }

      // &:before {
      //   position: absolute;
      //   content: "";
      //   display: block;
      //   top: 0;
      //   bottom: 0;
      //   right: 0;
      //   width: 1px;
      //   height: 50px;
      //   margin: auto;
      //   background-color: $pale-black;
      // }
    }
  }
}

//グローバルメニュー表示のアニメーション
@keyframes menuFadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nav-cart {
  display: flex;
  align-items: center;

  &:hover {
    &:before {
      display: none;
    }
  }

  .cart-icon {
    font-size: 1.5rem;
  }
}

.toggle-hamburger {
  display: none;
  @include mediaS {
    $width: 32px;
    $height: 24px;
    $bar-height: 4px;
    display: block;
    width: $width;
    height: $height;
    z-index: 210;
    & span {
      display: block;
      width: $width;
      height: $bar-height;
      background-color: black;
      transition: all 0.4s;
      &:nth-child(n + 2) {
        $bar-space: $height - $bar-height * 3;
        margin-top: $bar-space / 2;
      }
    }
    &.close {
      // barのY軸移動距離を算出
      $moveY: ($height - $bar-height) / 2;
      & span:nth-child(1) {
        transform: translateY($moveY) rotate(45deg);
      }
      & span:nth-child(2) {
        opacity: 0;
      }
      & span:nth-child(3) {
        transform: translateY(-#{$moveY}) rotate(-45deg);
      }
    }
    &.open {
      transform: translateX(0);
    }
  }
}
.headerNav__list {
  position: relative;
  text-align: center;
}

// .page-header.change-color {
//   @include pc {
//     background-color: $gray;
//   }
// }
.entry-button {
  width: 220px;
  text-align: center;

  @include mediaM {
    display: none;
  }
  a {
    padding-top: 32px;
    padding-bottom: 32px;
    // background-color: white;
    color: white;
    width: 100%;
    display: inline-block;
  }
}
.headerNav__list__link {
  font-family: $fonttitle;

  border-bottom: 2px solid $yellow;
  // font-weight: 800 !important;
}


