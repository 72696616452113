.toppage {
background-image: url(../img/mv.png);
background-size: cover;
background-repeat: no-repeat;
  width: 100%;
padding-top: 80px;

@include mediaM{
  background-image: url(../img/mv-sp.png);
}


 


  

  .toppage-wrapper {
    height: 600px;
    max-width: 1200px;
    height: 600px;

    @include mediaM {
      height: 480px;
    }
  }

  .toppage-content {
    .mainvisual-title{
      margin-left: 80px;
      display: flex;
    flex-direction: column;
    margin-top: 270px;
    @include mediaM{
      margin-left: 20px;
      margin-top: 300px;
    }

      h1{
        display: inline-block;
        font-size: 70px;
        color:white;
        background-color:#2D2D2D;
        padding-left: 20px;
        padding-right: 20px;
        @include mediaM{
          font-size: 40px;
          padding-left: 12px;
          padding-right: 12px;
        }
      }
      h2{
        display: inline-block;
        font-size: 50px;
        background-color:#2D2D2D;
        padding-left: 20px;
        padding-right: 20px;
        color:white;
        @include mediaM{
          font-size: 30px;
          padding-left: 12px;
          padding-right: 12px;
        }



      }

    }
    .mainvisual-text{
      margin-left: 80px;
      margin-top: 20px;
      @include mediaM{
        margin-left: 20px;
        p{
          font-size: 16px;
        }
      }
    }
   


   

    .toppage-logo {
      width: 80px;
      margin-bottom: 30px;

      @include pc {
        width: 150px;
        margin-bottom: 0;
      }
    }

    .main-logo {
      width: 80px;
      height: 80px;

      @include pc {
        width: 150px;
        height: 150px;
      }
    }

    .toppage-introduction {
      // width: calc(100% - 30px - 80px);
      margin-left: 30px;

      @include pc {
        margin-left: 200px;
        width: calc(100% - 200px - 150px);
      }

      h1 {
        font-size: 26px;
        //   color: black;
        width: 100%;

        @include pc {
          width: 500px;
          font-size: 40px;
        }
      }

      p {
        //   color: black;
        font-size: 18px;

        @include pc {
          margin-top: 28px;
          font-size: 20px;
        }
      }
    }
  }

}


.slant-bg {
  width: 100%;
  margin: 0;
  padding: 200px 0 100px;
  position: relative;
  top: 0;
  overflow: hidden;
}



.slant-bg::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  transform: skewY(7deg) translateY(90px);
  z-index: -1;
}

#about{
  background-image: url(../img/k-logo.png);
  background-size: 520px;
  background-position: -100px 100px;
  // background-size: contain;
  background-repeat: no-repeat;
  .about-flex{
    display: flex;
    justify-content:space-between;
  align-items: center;
    gap: 80px;
    margin-bottom: 40px;
    @include mediaM{
      display: block;
    }
    .about-photo{
      width: 500px;
      @include mediaM{
        width: 100%;
        margin-bottom: 40px;
      }
      img{
        width: 100%;
      }

    }
    .about-text{
      width: calc(100% - 580px);
      @include mediaM{
        width: 100%;
      }
      .about-ttl{
        h2{
          color: #FFF;
font-size: 30px;
font-family: $fonttitle;
font-weight: 700;
@include mediaM{
  font-size: 20px;
}
        }
      }
      .about-exp{
        margin-top: 60px;
        @include mediaM{
          margin-top: 30px;
        }
        p{
          color: #FFF;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;
        }
      }

    }
  }
}

#service{
  height: 550px;
  @include mediaM{
    height: 800px;

  }
  .service-flex{
    display: flex;
    height: 550px;
    gap: 2px;
    @include mediaM{
      height: 800px;
      flex-direction: column;
    }

    .banner_1 {
      height: 550px;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 100%;
    
   
      text-shadow: 0 0 12px #7b7a35;
      padding: 15px 0 10px 0;
      @include mediaM{
        width: 100%;
        height: 400px;
      }
  }
  .banner_1:after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      background: url(../img/service-lifting.png) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      background-size: cover;
      -webkit-transition: all .3s ease-out;
      -moz-transition: all .3s ease-out;
      -ms-transition: all .3s ease-out;
      transition: all .3s ease-out;
  }
  .banner_1:hover:after {
      opacity: .8;
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
  }
  .banner_1 .inner {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 100%;
  }
    .service-lifting{
      width: 65%;
      // background-image: url(../img/mv.png);
      background-size: cover;
      background-repeat: no-repeat;
      @include mediaM{
        width: 100%;
      }


      }
      .text-box{
     
        text-align: center;
        text-shadow: none;
        
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    color:white;

 
      }
      .more-link{
        background-color: black ;
        color:white;

      }
      .number{
        color: #FFF;
font-size: 14px;
font-family: $fonttitle;
font-weight: 700;
margin-bottom: 10px;

      }
    .service-name{
      color: #FFF;
font-size: 24px;
font-family: $default;
font-weight: 700;
margin-bottom: 32px;
    }

    }
  }

  .service-creative{
    width: 35%;
    @include mediaM{
      width: 100%;
    }
    .banner_1-creative {
      height: 550px;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 100%;
   
      text-shadow: 0 0 12px #7b7a35;
      padding: 15px 0 10px 0;
      @include mediaM{
        height: 400px;
      }
  }
  .banner_1-creative:after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      background: url(../img/service-creative.png) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -ms-background-size: cover;
      background-size: cover;
      -webkit-transition: all .3s ease-out;
      -moz-transition: all .3s ease-out;
      -ms-transition: all .3s ease-out;
      transition: all .3s ease-out;
  }
  .banner_1-creative:hover:after {
      opacity: .8;
      -moz-transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
  }
  .banner_1-creative .inner {
      z-index: 1;
      position: relative;
      width: 100%;
      height: 100%;
  }
 

  }


  .bbs{
    align-items: center;
    background:$yellow;
    color: black;
    display: flex;
    font-family: $default;
    height: 120px;
    line-height: 40px;
    overflow:hidden;
    width: 100%;
    white-space:nowrap;
    margin-top: 24px;
    z-index: 1;
    font-weight: bold;
    @include mediaM{
      height: 80px;


      margin-top: 52px;
    }
  }
  .bbs ul {
    animation: flowing 100s linear infinite;
    font-size: 120px;
    transform:translateX(100%);
    margin: 0;
    padding: 0;
    @include mediaM{
      font-size: 50px;
    }
  }
  .bbs ul li{
    display: inline-block;
    padding-right: 10px;
  }
  @keyframes flowing {
    0% { transform:translateX(0); }
    100% { transform:translateX(-100%); }
  }

#whychooseus{
  padding-left: 80px;
  @include mediaM{
    padding-left: 20px;
    padding-right: 20px;
  }
  .whychooseus-box{
    .whychooseus-flex{
      margin-top: 42px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      @include mediaM{
        display: block;
      
      }

      .reason-points{
        width: 40%;
        @include mediaM{
          margin-bottom: 30px;
          width: 100%;
        }


      }
      .reason-img{
        overflow: hidden;
        width: 60%;
        background-image: url(../img/frame-img.png);
        position: relative;
        z-index: -2;
        background-size: 500px;
        background-repeat: no-repeat;
      
        background-position: 0px 40px;
        @include mediaM{
          width: 100%;
        }
        .img1{
          width: 100%;
         display: flex;
         justify-content: end;
          img{
            width: 500px;
            @include mediaM{
              width: 300px;
            }
          }

        }
        .img2{
          width: 400px;
          margin-left: 30px;
          margin-top: -70px;
          @include mediaM{
            width: 230px;
          }
          img{
            width: 100%;
          }

        }
        .img3{
          position: relative;
          z-index: -1;
          margin-top: -80px;
          width: 430px;
          margin-left: 80px;
          @include mediaM{
            width: 300px;
          }
          img{
            width: 100%;
          }

        }

      }
    }
  }
}  

.points{
  .point{
    margin-bottom: 50px;
    .point-ttl{
      display: flex;
      align-items: center;

      margin-bottom: 32px;
      .point-number{
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 50%;
        background-color: $yellow;
        color:black;
        margin-right: 20px;
      }
    }
    h2{
      font-size: 20px;
font-family: $default;
font-weight: 700;
letter-spacing: 2px;

    }
    p{
      color: #000;
font-size: 16px;
font-family: $default;
font-weight: 600;
letter-spacing: 1.6px;
    }
  
  }
}

#contact{
  background-image: url(../img/estimate.png);
  background-size: cover;
  background-repeat: no-repeat;

  height: 380px;

  @include mediaM{
    height: 240px;
  }
  .common-contact-button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
    @include mediaM{
      height: 240px;
    }
    .contact-link{
      padding-top: 44px;
      padding-bottom: 44px;
      padding-left: 80px;
      padding-right: 130px;
      position: relative;
      background-color: white;
      color:black;
      @include mediaM{
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 40px;
        padding-right: 80px;
      }


    }

    .contact-link::before { /* くの字の表示設定 */
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 80px;
      width: 7px;    /* くの字を山なりに見た時、左側の長さ */
      height: 7px;   /* くの字を山なりに見た時、右側の長さ */
      border-top: 3px solid $yellow;     /* くの字を山なりに見た時、左側の太さと色 */
      border-right: 3px solid $yellow;   /* くの字を山なりに見た時、右側の太さと色 */
      transform: rotate(45deg); 
         /* くの字の向き */
         @include mediaM{
          right: 40px;
         }
    }
  }
}

#family{
  padding-left: 80px;
  padding-top: 84px;
  @include mediaM{
    padding-left: 0px;
    padding-right: 0px;
  }
  .family-ttl{
    padding-left: 20px;
  }
  .family-intro-flex{
    position: relative;
    margin-top: 42px;
    display: flex;
 justify-content: space-between;
 @include mediaM{
  display: block;
  padding-left: 20px;
  padding-right: 20px;
 }
    .family-intro-text{
      width: 40%;
      @include mediaM{
        width: 100%;
      }
      h2{
        color: #000;
font-size: 30px;
font-family: $fonttitle;
font-weight: 700;
      }
.intro-exp{
  margin-top:100px ;
  padding-left: 40px;
  padding-right: 40px;
  @include mediaM{
    margin-top: 70px;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
  }
}
      p{
        color: #FFF;
font-size: 16px;
font-family:$default;
font-weight: 700;
letter-spacing: 1.6px;
      }


    }
    .family-photo{
      width: 60%;
      @include mediaM{

        width: 100%;
      }
      img{
 
        width: 100%;
      }
      
    }


  }

  .family-intro-flex::before{
 content:"";
 width: 100%;
 height: 90%;
 position: absolute;
 bottom:-60px;
 background-color: black;
 left: 0;
 z-index: -3;

  }
  
}
#recruit{
  padding-top: 100px;
  background-image: url(../img/triangle.png),url(../img/triangle2.png);
  background-size: 300px;
  background-repeat: no-repeat;
 background-position: 100% -100px,-30px 150%;
 @include mediaM{
  background-size: 200px;

 }
}

.recruit-flex{
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 76%;
  margin:  0 auto;
  align-items: center;
  // .img-wrapper{
  //   width: 60%;

  // }
  @include mediaM{
    display: block;
  }
  .staffsimg{
    width: 60%;
    @include mediaM{
      width: 100%;
      margin-bottom: 30px;
    }
    .img1{
      width: 100%;
    }

  }
  .recruit-text{
    text-align: center;
    width: 40%;
    @include mediaM{
      width: 100%;
    }
    p{
      color: #000;
font-size: 18px;
font-family: $default;
font-weight: 900;
letter-spacing: 1.8px;
@include mediaM{
  font-size: 16px;
}
    }
  }
}
.recruit-link{
  background-color: black;
  margin-top: 64px;
  color:white;
}

#news{
  .news-box{
    margin-top: 42px;
  }
  .news-category-list{
    display: flex;
    gap: 60px;

    li{ 
     a{
        color: #000;
font-size: 18px;
font-family: $fonttitle;
font-weight: 700;


    }
  }
  }
  .news-content-box{

    margin-top: 50px;
    .news-content{
      position: relative;



    }

    .news-content-list{
      li{
        padding-bottom: 30px;
        padding-top: 30px;
        border-bottom: 1px solid #000;
        @include mediaM{
          padding-right: 30px;

        }
      }
      .news-ttl{
        display: flex;
        gap: 20px;
        .date{
          color: #000;
font-size: 14px;
font-family:$fonttitle;
font-weight: 700;

        }
        .category-ttl{
          color: #FFE601;
font-size: 16px;
font-family:$fonttitle;
font-weight: 700;

        }
      }
      .news-details{
        margin-top: 30px;
        p{
          color: #000;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;

        }
      }
    }
  }
  .news-content::before { /* くの字の表示設定 */
    content: "";
    margin: auto;
    position: absolute;
    top: 50%;

    right: 22px;
    width: 16px;    /* くの字を山なりに見た時、左側の長さ */
    height: 16px;   /* くの字を山なりに見た時、右側の長さ */
    border-top: 3px solid $yellow;     /* くの字を山なりに見た時、左側の太さと色 */
    border-right: 3px solid $yellow;   /* くの字を山なりに見た時、右側の太さと色 */
    transform: rotate(45deg);    /* くの字の向き */
    @include mediaM{
      top: 30px;
    
      left: 300px;
    }
  }
}
.news-content-box{
  margin-bottom: 42px;
}
.news-details-content{
  margin-top: 30px;

img{
  width: 60%;
  margin-bottom: 20px;
  @include mediaM{
    width: 100%;
  }
}
p{
  width: 70%;
  color: #000;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;
@include mediaM{
  width: 100%;
}

}
}
.yellow{
    background-color: $yellow !important;

}

.news-ttl{
  display: flex;
  gap: 20px;
  .date{
    color: #000;
font-size: 14px;
font-family:$fonttitle;
font-weight: 700;

  }
  .category-ttl{
    color: #FFE601;
font-size: 16px;
font-family:$fonttitle;
font-weight: 700;

  }
}

.news-button{
  margin-top: 40px;
}