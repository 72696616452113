.pages-top{
    padding-top: 80px;
    height: 400px;
    // width: 100%;
    background-color: black;
    padding-left: 80px;
    @include mediaM{
        height: auto;
        padding-top: 70px;
        padding-left: 20px;
    }

    .pages-top-flex{
        height: 400px;
        display: flex;
    


    align-items: end;
    @include mediaM{
        height: 250px;
flex-direction: column-reverse;
    }


        .ttl-wrapper{
            width: 40%;
            padding-bottom: 50px;
            position: relative;
            z-index:1;
            @include mediaM{
                margin-top: -200px;
                width: 100%;
            }
            .sub-ttl{
                p{
                    color: #FFF;
font-size: 14px;
font-family: $fonttitle;
font-weight: 700;
                }
            }
            .pages-main-ttl{
                h2{
                    color:white;
font-size: 90px;
font-family: $default;
font-weight: 900;
@include mediaM{
    font-size: 50px;
}
                }
            }
            .pages-exp-text{
                margin-top: 26px;
                @include mediaM{
                    margin-top: 12px;
                }
                p{
                    color: #FFF;
font-size: 16px;
font-family: $default;
font-weight: 900;
letter-spacing: 1.6px;
@include mediaM{
    font-size: 14px;
}
                }
            }


        }
        .top-img-wrapper{
            width: 60%;
            height: 400px;
            @include mediaM{
                width: 100%;
            }
            img{
                height: 400px;
                width: 100%;
                @include mediaM{
                    height: 250px;
                }
            }
        }
    }

}
.template-about{
    margin-top: 82px;
    @include mediaM{
        margin-top: 40px;
    }
}
.slant-bg-two {
    width: 60%;
    // padding: 140px 0;
    height: 400px;
    clip-path: polygon(0 calc(0% + 7vw), 100% 0, 100% calc(100% - 7vw), 0 100%);
    background-color:$yellow;
    position: relative;
    z-index: -2;
    @include mediaS{
        margin-top: -70px;
        position: relative;
        z-index: 1;
    }

    .message-content{
        padding-top: 100px;
        padding-left: 120px;
        @include mediaS{
            padding-top: 60px;

            padding-left: 20px;
        }
        p{
            display: flex;
width: 390px;
// height: 229px;
flex-direction: column;
flex-shrink: 0;
color: #000;
font-size: 16px;
font-family: $default;
font-weight: 800;
letter-spacing: 1.6px;
@include mediaS{
    width: 100%;
}
        }
    }
}

.message-ttl{
    padding-left: 120px;
    @include mediaS{
        padding-left: 20px;
    }
    h2{
        color: black;
        font-size: 50px;
font-family: $default;
font-weight: 900;
letter-spacing: 5px;
@include mediaM{
    font-size: 24px;
}
    }
    p{color: #000;
        text-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
        font-size: 18px;
        font-family: $default;
        font-weight: 800;
        letter-spacing: 1.8px;
    @include mediaM{
        font-size: 16px;
    }}
}
.top-message-flex{
    display: flex;
    align-items: center;

    @include mediaS{
        padding-left: 20px;
        padding-right: 20px;
       flex-direction: column-reverse;
    }

    .message{
        @include mediaM{
            width: 100%;
        }
    }
    .top-img{
        margin-top:-250px;
        width: 380px;
        margin-left: -60px;
        @include mediaM{
            margin-top: 0;
            margin-left: 0px;
            width: 40%;
            width: 100%;
     
        }
        img{
            width: 100%;
        }
    }
}
.top-name{
    color: #000;
font-size: 16px;
font-family:$default;
font-weight: 800;
letter-spacing: 1.6px;
@include mediaM{
    margin-top: 16px;
}
}
.top-name{
    display: flex;
    justify-content: end;
}

.philosophy-section{
    background-color: black;
    .text-white{
        h2{
            color:white;
        }
        h3{
            color: white;
        }
    }
    .philosophy-list{
        margin-top: 42px;

      
        .philosophy{
            display: flex;
            gap: 30px;
            padding-top: 30px;

            padding-bottom: 30px;
            border-bottom: 1px solid $yellow;
            @include mediaM{
                display: block;
            }
            .philo-title-wrapper{
                width: 35%;
                @include mediaM{
                    width: 100%;
                    margin-bottom: 20px;
                }
                .number{
                    color: #FFF;
font-size: 16px;
font-family: $default;
font-weight: 800;
letter-spacing: 1.6px;
padding: 10px;
background-color: $yellow;
display:inline-block;

                }
                .philo-ttl{
                    
                    color: #FFF;
                    text-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25);
                    font-size: 24px;
                    font-family: $default;
                    font-weight: 900;
                    letter-spacing: 2.4px;
                    @include mediaM{
                        font-size: 18px;
                    }

                }

            
            }
            .philo-details{
                width: 75%;
                @include mediaM{
                    width: 100%;
                }
                p{
                    color: #FFF;
font-size: 16px;
font-family: $default;
font-weight: 700;
line-height: 30px;
letter-spacing: 1.6px;

                }
            }

        }
    }

}

.company {
    //   padding-bottom: rem(120);
    @include mediaM {
      margin-right: 15px;
      margin-left: 15px;
    }

  }
  
  .company__wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 42px;
      //   flex-direction: row-reverse;
      @include mediaM{
        display: block;
      }
 

  }
  
  .company__items {
    width: calc(100% - 43%);
    @include mediaM {
      width: 100%;
      // max-width: rem(673);
      // margin-left: rem(60);
    }
  }
  
  .company__item {
    position: relative;
    display: flex;
    justify-content: space-between;
  
    align-items: center;
    border-bottom: 1px solid $yellow;
    margin-top: 12px;
    margin-bottom: 12px;
    @include mediaM {
      margin-left: 15px;
    }
    //   @include mediaM {
    //     padding-top: rem(12);
    //     padding-bottom: rem(12);
    //   }
  }
  
  .company__term {
    padding-bottom: 12px;
    color: #000;
    font-size: 16px;
    font-family:$fonttitle;
    font-weight: 700;
    letter-spacing: 1.6px;
    //   max-width: rem(90);
    width: 18%;
    @include mediaM {
      font-size: rem(16);
    }
  }
  
  
  .company__description {
    text-align: left;
    width: calc(100% - 25%);
    padding-bottom: 12px;
    color: #000;
    font-size: 16px;
    font-family: $fonttitle;
    font-weight: 600;
    letter-spacing: 0.48px;
    margin-left: rem(23);
    @include mediaM {
      font-size: rem(16);
    }
  }
  
  .company__map {
    position: relative;
    width: 40%;
    //   margin-left: calc(50% - 50vw);
    margin-top: rem(18);
    @include mediaM {
      width: 100%;
      margin-top: 0;
    }
  }
  
  .company__map::before {
    content: "";
    display: block;
    padding-top: 49.318%;
    @include mediaM {
      padding-top: 98.639%;
    }
  }
  
  .company__map iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .company__btn {
    margin-top: rem(40);
    @include mediaM {
      margin-top: rem(80);
    }
  }
  
  .company--page {
    padding-top: rem(120);
    padding-bottom: rem(120);
    @include mediaM {
      padding-top: rem(251);
      padding-bottom: rem(250);
    }
    .company__title {
      @include mediaM {
        text-align: right;
      }
    }
    .section-header__title {
      @include mediaM {
        margin-left: auto;
        width: fit-content;
      }
    }
  }
  
  .company--page .company__wrapper {
    @include mediaM {
      margin-left: calc(50% - 50vw);
    }
  }
  
  .company--page .company__map {
    max-width: none;
  }