footer{
    background-image: url(../img/footer-klogo.png);
    background-size: 610px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 110% 130px;
 
    background-color: black;
@include mediaM{
    background-size: 300px;
    background-position: 110% 100%;
}
    .footer-wrapper{
        width: 60%;
        @include mediaM{
            width: 100%;
        }
    }
    .footer-contact-ttl{
        padding-bottom: 30px;
        border-bottom: 1px solid white;
        h2{
            color: #FFF;

font-size: 30px;
font-family: $default;
font-weight: 900;
letter-spacing: 3px;
        }
    }

    .footer-address{
        margin-top: 30px;
        margin-bottom: 20px;
        p{
            color: #FFF;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;
        }
    }

    .footer-button{
        margin-bottom: 30px;
        .googlemap{
            .googlemap-link{
                margin-left: 20px;
                position: relative;
                color: #FFF;
font-size: 14px;
font-family: $default;
font-weight: 700;
padding-bottom: 8px;
border-bottom: 1px solid $yellow;
            }
        }
        .googlemap-link:before{
            content:"";
            background-image: url(../img/googlemap.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 14px;
            height: 20px;
            position: absolute;
            left: -20px;
            top: 0;


        }

        .phone{
            margin-top: 20px;
            .phone-link{
                margin-left: 20px;
                position: relative;
                color: #FFF;
font-size: 14px;
font-family: $default;
font-weight: 700;
padding-bottom: 8px;
border-bottom: 1px solid $yellow;
            }
        }
        .phone-link:before{
            content:"";
            background-image: url(../img/phone.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 14px;
            height: 20px;
            position: absolute;
            left: -20px;
            top: 0;


        }

        }

        .footer-sns{
            padding-bottom: 30px;
            border-bottom: 1px solid white;
            .sns-flex{
                display: flex;
                gap: 20px;
                .facebook{
                    width: 32px;
height: 32px;
img{
    width: 100%;
}
                }
                .insta{
                    width: 32px;
                    height: 32px;
                    img{
                        width: 100%;
                    }
                }
            }

          
        }
        .footer-menu-list{
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
            margin-top: 30px;
            @include mediaM{
                
            justify-content: start;

            }
    
            .menu-list{
                margin-bottom: 20px;
                width: calc((100% - 155px )/ 3) ;
                @include mediaM{
                    width: calc((100% - 155px )/ 2) ;
                
                    
        
                    }
                a{
                    color: #FFF;
font-size: 18px;
font-family: $default;
font-weight: 700;
@include mediaM{
    font-size: 16px;
}
                }
            }
        }
    }

    



