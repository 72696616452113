.template-service-section{
    .service-lifting{
        overflow: hidden;
    
        .list-flex{
            position: relative;
            margin-top: 100px;
            display: flex;
            justify-content: space-between;
            gap: 50px;
            @include mediaM{
                display: block;

            }

            .lifting-text{
                width: 45%;
                @include mediaM{
                    width: 100%;
                   
                }

            }
            .lifting-img{
                width: 55%;
                @include mediaM{
                    width: 100%;
                    margin-bottom: 20px;
                }
                img{
                    width: 100%;
                }
            }
            .lifting-text{
                h4{
                    color: #000;
font-size: 18px;
font-family: $default;
font-weight: 800;
letter-spacing: 1.8px;

                }
                .lifting-ttl{
                    color: #000;
font-size: 24px;
font-family: $default;
font-weight: 900;
letter-spacing: 2.4px;
@include mediaM{
    font-size: 20px;
}
                }
            }
            p{
                margin-top: 20px;
                color: #000;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;
            }
        }

        .list-flex::after{
            content:"";
            width: 130px;
            height: 210px;
            background-color: $yellow;
            position: absolute;
            left: -80px;
            top: -40px;
            z-index: -2;
            @include mediaM{
                height: 150px;
            }
        }
    }
}

.creative-list-flex{
    position: relative;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    gap: 50px;

    @include mediaM{
        overflow: hidden;
        display: block;

    }

    .lifting-text{
        width: 45%;
        @include mediaM{
            width: 100%;
           
        }

    }
    .lifting-img{
        width: 55%;
        @include mediaM{
            width: 100%;
            margin-bottom: 20px;
        }
        img{
            width: 100%;
        }
    }
    .lifting-text{
        h4{
            color: #000;
font-size: 18px;
font-family: $default;
font-weight: 800;
letter-spacing: 1.8px;

        }
        .lifting-ttl{
            color: #000;
font-size: 24px;
font-family: $default;
font-weight: 900;
letter-spacing: 2.4px;
@include mediaM{
font-size: 20px;
}
        }
    }
    p{
        margin-top: 20px;
        color: #000;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;
    }
}

.creative-list-flex::after{
    content:"";
    width: 130px;
    height: 210px;
    background-color: $yellow;
    position: absolute;
    right: -80px;
    top: -40px;
    z-index: -2;
    @include mediaM{
        height: 150px;
    }
}
.lifting-related-services{
    .related-services{
        display: flex;
        justify-content: space-between;
        gap: 100px;
        @include mediaM{
            display: block;

        }
        .related-service{
            width: calc((100% - 200px)/ 3);
            @include mediaM{
                width: 100%;
                margin-bottom: 40px;
            }
            .service-img{
                margin-bottom: 20px;
                width: 100%;
                img{
                    width: 100%;
                }
            }
            .service-ttl{
                margin-bottom: 20px;
              
                h2{
                    display: inline-block;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $yellow;
                    color: #000;
                    font-size: 24px;
                    font-family: $default;
                    font-weight: 700;
                    letter-spacing: 2.4px;
                    @include mediaM{
                        font-size: 18px;
                    }
                }
            }
            .service-exp{
                p{
                    color: #000;
font-size: 16px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.6px;
                }
            }
        }
    }

}

.happy-tri-section{
    margin-bottom: 100px;
    .tri-flex{
        display: flex;
        justify-content: center;
        align-items: center;
        @include mediaM{
            display: block;
        }
        .triangle-img{
            width: 50%;
            @include mediaM{
                width: 100%;
            }
            
            .img-wrapper{
                width: 410px;
                @include mediaM{
                    width: 100%;
                }
                img{
                    width: 100%;
                }
            }
        }
        .tri-exp{
            width: 40%;
            @include mediaM{
                width: 100%;
            }
            .kanri{
                margin-bottom: 15px;
                p{
                    color: #000;
font-size: 18px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.8px;

                }
                


            }
            .kanri-name{
                display: flex;
                margin-bottom: 10px;
                p{
                    color: #000;
                    font-size: 18px;
                    font-family: $default;
                    font-weight: 700;
                    letter-spacing: 1.8px;

                }
            }
            .worker{
                margin-bottom: 15px;
                p{
                    color: #000;
font-size: 18px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.8px;
                }

            }
            .threek{
                margin-bottom: 15px;
                p{
                    color: #000;
font-size: 18px;
font-family: $default;
font-weight: 700;
letter-spacing: 1.8px;
                }

            }
        }

    }

}

.creative-contact{
    background-image: url(../img/creative-contact.png);
  
  background-size: cover;
  background-repeat: no-repeat;

  height: 380px;


  @include mediaM{
    height: 240px;
  }
  .common-contact-button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
    @include mediaM{
      height: 240px;
    }
    .contact-link{
      padding-top: 44px;
      padding-bottom: 44px;
      padding-left: 80px;
      padding-right: 130px;
      position: relative;
      background-color: white;
      color:black;
      @include mediaM{
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 40px;
        padding-right: 80px;
      }


    }

    .contact-link::before { /* くの字の表示設定 */
      content: "";
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 80px;
      width: 7px;    /* くの字を山なりに見た時、左側の長さ */
      height: 7px;   /* くの字を山なりに見た時、右側の長さ */
      border-top: 3px solid $yellow;     /* くの字を山なりに見た時、左側の太さと色 */
      border-right: 3px solid $yellow;   /* くの字を山なりに見た時、右側の太さと色 */
      transform: rotate(45deg); 
         /* くの字の向き */
         @include mediaM{
          right: 40px;
         }
    }
  }
}