.contact{
    display: flex;
    justify-content: center;
    .contact-wrapper{
        @include mediaM{
            width: 100%;
        }
    }



    .template-contact-ttl{
        text-align: center;
        margin-bottom: 30px;
        h3{
            color: #000;
            text-align: center;
            font-size: 18px;
            font-family: $default;
            font-weight:800;
            letter-spacing: 1.6px;

        }

    }
    .contact-form-text{
        margin-bottom: 30px;
        p{
            color: #000;
text-align: center;
font-size: 16px;
font-family: $default;
font-weight:700;
letter-spacing: 1.6px;
        }
    }
    .form-section{
        display: flex;
        justify-content: center;


    }

    .form-section{
        width: 100%;
    }
    

}

.wp-block-contact-form-7-contact-form-selector{
    width:100% !important;
}
.wpcf7{
    width: 100% !important;
}
.wpcf7-form{
    width: 100% !important;

}
.contact__form{
    width: 90%!important;
    margin: 0 auto;
    @include mediaM{
        width: 100%;
    }
    tr{
        display: flex;
        justify-content: start;
        gap: 20px;
    }

    th{
        width: 30%;
        text-align-last: left;
    }
    td{
        width: 70%;
    }
    input{
        margin-bottom: 14px !important;
    }
}

.wpcf7-form-control-wrap{
    width: 100% !important;
    input{
        width: 100%;
        border-radius: 0 !important;
    border:1px solid grey;
    padding: 8px;
    }
   textarea{
    width: 100% !important;
   }
}

.contact__submit{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .wpcf7-submit{
        color:black;
        font-size: 16px;
        font-family: $fonttitle;
        font-weight: 700;
        padding-left: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right:40px ;
        background-color:$yellow;
        display: inline-block;
        position: relative;
    border: none !important;

    }

}
.wpcf7-checkbox{
display: flex;
flex-direction: column;
align-items: start;
margin-bottom: 20px;
label{
    display: flex;

    input{
        width:10px !important;text-align: left;
    }
}


}